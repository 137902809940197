import React from 'react';
import PropTypes from 'prop-types';

import { Video } from 'grommet';

import SmartLink from '../SmartLink';
import useMobile from '../useMobile';
import { storyblokImageClient } from '../../lib/imgix';

const DynamicVideoContentModule = ({ blok }) => {
  const {
    video,
    autoPlay = false,
    mute = true,
    loop = false,
    controls = 'below',
    fit = 'contain',
    fill,
    link,
    poster,
    desktopOnly,
  } = blok;
  const isMobile = useMobile();
  if (isMobile && desktopOnly) {
    return null;
  }
  const controlsVal = controls === 'none' ? false : controls;
  if (!video || !video.filename) {
    return null;
  }
  const to = link
    ? link.linktype === 'story' && link.cached_url !== ''
      ? `/${link.cached_url}`
      : link.url
    : undefined;

  const posterUrl =
    poster && poster.filename
      ? storyblokImageClient.buildURL(poster.filename, {
          q: 75,
          'max-w': 500,
          auto: ['compress', 'format'],
        })
      : undefined;

  return (
    <SmartLink to={to} fill={fill}>
      <Video
        autoPlay={autoPlay}
        mute={mute}
        width={fill ? '100%' : 'auto'}
        height={fill ? '100%' : 'auto'}
        loop={loop}
        controls={controlsVal}
        fit={fit}
        poster={posterUrl}
      >
        <source key="video" src={video.filename} type="video/mp4" />
      </Video>
    </SmartLink>
  );
};

DynamicVideoContentModule.propTypes = {
  blok: PropTypes.shape({
    video: PropTypes.object.isRequired,
    autoPlay: PropTypes.bool,
    mute: PropTypes.bool,
    loop: PropTypes.bool,
    controls: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    fit: PropTypes.string,
    fill: PropTypes.bool,
    link: PropTypes.object,
    poster: PropTypes.object,
    desktopOnly: PropTypes.bool,
  }),
};

export default DynamicVideoContentModule;
